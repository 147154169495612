.artist-details-page-container{
  margin-bottom: 33px;
  margin-left: 2em;
  margin-right: 2em;
}

.artist-details-container {
    display: flex;
    gap: 23px;
    margin-bottom: 55px;
  }

  .artist-details-info-container div:nth-child(even) {
    padding-bottom: 23px;
  }

.artist-details-image{
    display: flex;
    max-width: 73vw;
    max-height: 73vh;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3)
}

.artist-details-artworks-title{
  margin-top: 2.5em;
  margin-left: 13px;
}

.no-art-to-display{
  margin-top: 1.5em;
  margin-left: 21px;
  color: orange;
  font-size:x-large;
  font-weight: bolder;

}
