.delete-art-modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1em;

    background-color: rgba(24, 24, 24);
    border: solid 3px lightsteelblue;
}


.delete-art-modal-container div:not(:first-child) {
    padding-top: 1em; /* Adjust the value as needed */
  }


.delete-art-piece-image{
    max-height: 35vh;

}
