.art-info-loading {
    display: none;
    /* color: blue;
    margin-left: 50%;
    margin-right: 50%;
    justify-content: center;
    margin-top: 200px;
    font-size: 31px; */
}


.landing-page-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-between;
    margin: 3em;
    margin-top: 5em;
    align-items: center;
    gap: 3em;
}

.landing-page-container > div:last-child .landing-page-art-image{
    /* CSS properties for the last row */
    /* For example, change the background color */
    max-height: 55vh;
}




.landing-page-art-piece-card {
    /* background-color: red; */
    /* position: relative; */
    /* max-width:fit-content; */
    /* padding: 1.5em; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    flex: calc(27%);
    /* margin: */
    /* margin: 5px; */
    /* align-self: center; */
    /* justify-self: center; */
    /* margin-bottom: 9em; */
    /* max-height: 75vh; */
    /* margin-bottom: 7em; */
    /* align-content: center; */

}

.landing-page-art-piece-card {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-bottom: 5em;
  }


.landing-page-art-image{
    transition: transform 0.7s ease;
}

.landing-page-art-image:hover{
  transform: scale(1.1);

}

.landing-page-art-image:active{
  transform: scale(1.5);
}

/* .landing-page-art-image-container:hover ~ .landing-page-art-piece-artist-info {
    transform: translateY(10px);
  } */

.landing-page-art-image{
    box-shadow: 0 0 1.3em lightsteelblue;
    transition: transform 0.7s ease;
    max-width: 100%;
    max-height: 100%;
}



.landing-page-art-piece-artist-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 100%; */
    justify-content: flex-start;
    /* padding-left: 5px; */
    margin-top: 1em;
}

.landing-page-artist-image-container {
    /* height: 75px; */
    /* max-width: 5vw; */
    min-width: 5vh;
    max-width: 7vh;
    /* margin-bottom: 2em; */
}


.landing-page-artist-image {
    margin-right: 5px;
    max-height: 100%;
    max-width: 100%;
    object-fit: fill;
    border-radius: 50%;
    border: solid .1em lightsteelblue;

}

.landing-page-artist-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-evenly; */
    /* color: lightslategray; */

    margin-left: 7px;
}

.landing-page-artist-name-display{
    color: lightsteelblue;
}

.landing-page-artist-navlink{
    text-decoration: none;
    border-radius: 50%;
}
