.all-artists-page-artist-details{
    margin-top: 17px;
}

.all-artists-page-artist-details > div {
    margin-bottom: 7px;
}

.all-artists-page-title{
    margin-left: 27px;
    margin-top: 37px;
}
