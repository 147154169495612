#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  /* display: flex;
  flex-direction: column; */
  position: absolute;
  /* background-color: rgba(24, 24, 24); */
  padding: 13px;
  /* border: solid 3px lightsteelblue;
  border-radius: 13px; */

}
