.remove-from-wishlist-button{
    margin-top: 1em;

}

.contact-artist-button{
    margin-top: 0.7em;
    font-size: 1.7vw;
}

.wishlist-artist-info{
    margin-bottom: 1em;
}

/*.wishlist-page-container{
    padding-top: 0em;
    margin-top: 0em;
    font-size: 1.5vw;
} */

.wishlist-page-title{
    margin-top: 2em;
    margin-left: 2em;
}

.no-wishlist-to-display{
    font-size: x-large;
    font-weight: bold;
    margin-left: 3em;
    color: orange;
}

.no-wishlist-to-display > li {
    padding-top: 1em;
}
